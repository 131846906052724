var scroll = require('./scroll.js');

/*
    home.js

    Setup the some functions specific to the home page.
*/

var navEl, navHeight;

function handleProgress(el) {
    scroll.addInViewFn(function() {
        window.setTimeout(function() {
            el.style.width = el.dataset.percent + '%';
        }, 300);
    }, el);
}

module.exports = {

    init: function () {
        document.addEventListener('DOMContentLoaded', function (event) {
            var els = document.querySelectorAll('.prog-scroll');
            if(els && els.length) {
                for(var i = 0; i < els.length; i++) {
                    els[i].style.width = 0;
                    handleProgress(els[i]);
                }
            }
        });
    }
}