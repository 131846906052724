/*
    scroll.js

    Handle scroll events in a common location. Can be used by other scripts to avoid
    having to reimplement the scroll event with every use
*/

var prevScrollPos = window.pageYOffset,
    fns = [],
    viewFns = [],
    viewEls = [];

const MAX_IN_VIEW = 200;

module.exports = {

    addScrollFn: function(fn) {
        if(typeof fn === 'function') {
            fns.push(fn);
        }
    },

    addInViewFn: function(fn, el) {
        if (typeof fn === 'function') {
            viewFns.push(fn);
            viewEls.push(el);
        }
    },

    checkInView: function(el) {
        var rect = el.getBoundingClientRect(),
            elemTop = rect.top,
            elemBottom = rect.bottom;

        // Only completely visible elements return true:
        return ((elemTop >= 0) && (elemBottom <= window.innerHeight)) || (window.innerHeight - elemTop > MAX_IN_VIEW);
    },

    init: function() {
        var self = this;
        window.addEventListener('scroll', function () {
            var i;
            if(fns.length) {
                var currentScrollPos = window.pageYOffset;
                for(i = 0; i < fns.length; i++) {
                    fns[i].call(window, currentScrollPos, prevScrollPos);
                }
                prevScrollPos = currentScrollPos;
            }
            if (viewFns.length) {
                var rm = [];
                for (i = 0; i < viewFns.length; i++) {
                    if(self.checkInView(viewEls[i])) {
                        viewFns[i].call(window, viewEls[i]);
                        rm.push(i);
                    }
                }
                if(rm.length) {
                    //once called, no longer a need to call it again, so remove the fn
                    for (i = 0; i < rm.length; i++) {
                        viewFns.splice(rm[i], 1);
                        viewEls.splice(rm[i], 1);
                    }
                }
            }
        });
    }
}
