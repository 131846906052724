require('../styles/index.scss');


var circle = require('./scripts/circle.js');
var scroll = require('./scripts/scroll.js');
var nav = require('./scripts/nav.js');
var footer = require('./scripts/footer.js');
var home = require('./scripts/home.js');
var contact = require('./scripts/contact.js');
var ss = require('./scripts/ss.js');

var msbroswers = require('./scripts/msbrowsers.js');

(function() {

    //utility services
    scroll.init();

    //page functionality
    nav.init();
    footer.init();
    circle.init();
    home.init();
    contact.init();
    ss.init();

    //fix MS junk
    msbroswers.init();

})();
