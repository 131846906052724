var scroll = require('./scroll.js');

/*
    ss.js

    Setup the MORI Solar System infographic include.
*/

window.adjustIframeHeight = function(i) {
    document.getElementById('ss-iframe').style.height = parseInt(i) + 'px';
}

module.exports = {

    init: function () {
        document.addEventListener('DOMContentLoaded', function (event) {
            var el = document.getElementById('ss-placeholder'), isSet = false;
            if(el) {
                scroll.addInViewFn(function() {
                    if(!isSet) {
                        isSet = true;
                        var iFrame = document.createElement('iframe');
                        iFrame.src = el.getAttribute('data-url');
                        iFrame.setAttribute('allowfullscreen', 'true');
                        iFrame.id = 'ss-iframe';
                        el.classList.add('hide');
                        el.parentElement.appendChild(iFrame);
                    }
                }, el);
            }
        });
    }
}
