var scroll = require('./scroll.js');

/*
    footer.js

    Setup the "to page top" button to show/hide on scroll, and scroll to the page top
    when clicked.
*/

const TOGGLE_ON_SCROLL = 300;

var toTopEl, toggleHeight;

module.exports = {

    init: function () {
        scroll.addScrollFn(function (currentScrollPos, prevScrollPos) {
            if (!toTopEl) {
                toTopEl = document.getElementById('to-top');
                if(toTopEl) { //avoid nulls
                    toTopEl.addEventListener('click', function() {
                        window.scrollTo(0, 0);
                    });
                    toggleHeight = toTopEl.clientHeight;
                }
            }

            if (toTopEl) { //avoid nulls
                if (currentScrollPos > TOGGLE_ON_SCROLL) {
                    toTopEl.style.bottom = '0.5em';
                } else {
                    toTopEl.style.bottom = (-toggleHeight * 2) + 'px';
                }
            }
        });
    }
}