var scroll = require('./scroll.js');

/*
    nav.js

    Setup the navigation bar show/hide on scroll mechanism.
*/

var navEl, navHeight;

module.exports = {

    init: function() {
        scroll.addScrollFn(function (currentScrollPos, prevScrollPos) {
            if (!navEl) {
                navEl = document.getElementById('nav');
                if(navEl) { //avoid nulls
                    navHeight = navEl.clientHeight;
                }
            }
            if (navEl) { //avoid nulls
                if (prevScrollPos > currentScrollPos || currentScrollPos < navHeight) {
                    navEl.style.top = '0';
                } else {
                    navEl.style.top = -navHeight + 'px';
                }
            }
        });

        document.addEventListener('DOMContentLoaded', function (event) {
            var navMobile = document.getElementById('nav-icon'),
                navMenu = document.getElementById('nav-items');
            navMobile.addEventListener('click', function() {
                navMenu.classList.toggle('active');
            }, false);
            document.addEventListener('click', function() {
                if(navMenu.classList.contains('active')) {
                    navMenu.classList.remove('active');
                }
            }, true);
        });
    }
}