/*
    circle.js

    Handle the touchscreen fallback for the circle flip.
*/

module.exports = {


    init: function () {
        document.addEventListener('DOMContentLoaded', function (event) {
            var els = document.querySelectorAll('flip-wrapper');
            if(els && els.length) {
                for(var i = 0; i < els.length; i++) {
                    els[i].addEventListener('touchstart', function() {
                        els[i].classList.toggle("flip");
                    });
                }
            }
        });
    }
}