/*
    contact.js

    Handle the validation and submission for the contact form.
*/

const FIELDS = [
    { name: 'firstName', required: true },
    { name: 'lastName', required: true },
    { name: 'email', required: true, mask: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ },
    { name: 'company' },
    { name: 'comment', required: true }
]

const FORM = 'contact-us';
const CONFIRM = 'contact-success';
const CLOSE = 'contact-close';
const ERROR = 'contact-error';
const CLOSE_ERR = 'contact-close-err';
const BTN = 'contact-btn';

var confirmEl, errorEl, btnEl;

function addBlur(field) {
    field.el.addEventListener('blur', function () {
        validateMask(field);
    });
}

function validateMask(field) {
    var val = field.el.value, isValid = true;
    if(field.el.value) {
        isValid = field.mask.test(field.el.value);
    }

    if(isValid) {
        field.el.classList.remove('invalid');
        field.maskEl.classList.remove('active');
    } else {
        field.el.classList.add('invalid');
        field.maskEl.classList.add('active');
    }
    return isValid;
}

function validateRequired(field) {
    var isValid = !!field.el.value;

    if (isValid) {
        field.el.classList.remove('invalid');
        field.requiredEl.classList.remove('active');
    } else {
        field.el.classList.add('invalid');
        field.requiredEl.classList.add('active');
    }
    return isValid;
}

function sendRequest(req, cb, errCb) {
    var xhr;
    xhr = new XMLHttpRequest();
    xhr.onload = function () {
        if(xhr.status === 200) {
            gtag && gtag('event', 'success', { 'event_category': 'Contact', 'event_label': 'success', });
            if(cb && typeof cb === 'function') {
                cb.call(window);
            }
        } else {
            gtag && gtag('event', 'failure', { 'event_category': 'Contact', 'event_label': 'response', });
            if (errCb && typeof errCb === 'function') {
                errCb.call(window);
            }
        }
    };
    xhr.onerror = function() {
        gtag && gtag('event', 'failure', { 'event_category': 'Contact', 'event_label': 'send', });
        if (errCb && typeof errCb === 'function') {
            errCb.call(window);
        }
    }
    xhr.open("POST", "https://ya1ofzj1a5.execute-api.us-east-1.amazonaws.com/default/mailfwd");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(req));
}

function handleForm(formEl, fields) {
    formEl.addEventListener('submit', function (ev) {
        ev.preventDefault();
        btnEl.disabled = true;
        var isValid = true, i, req = {};
        for(i = 0; i < fields.length; i++) {
            if(fields[i].el) {
                if(fields[i].mask) {
                    isValid = validateMask(fields[i]) && isValid;
                }
                if(fields[i].required) {
                    isValid = validateRequired(fields[i]) && isValid;
                }
                req[fields[i].name] = fields[i].el.value;
            }
        }
        if(isValid) {
            //dosubmit
            sendRequest(req, function() {
                confirmEl.classList.add('active');
                btnEl.disabled = false;
                for (i = 0; i < fields.length; i++) {
                    if (fields[i].el) {
                        fields[i].el.value = '';
                    }
                }
            }, function() {
                errorEl.classList.add('active');
                btnEl.disabled = false;
            });
        } else {
            btnEl.disabled = false;
            gtag && gtag('event', 'failure', { 'event_category': 'Contact', 'event_label': 'input', });
            ga && ga('send', 'event', 'Contact', 'failure', 'input');
        }
        //do not submit to default handler, as form will be handled with XHR
        return false;
    })
}

module.exports = {


    init: function () {
        document.addEventListener('DOMContentLoaded', function (event) {
            var formEl = document.getElementById(FORM);
            if(formEl) {
                for(var i = 0; i < FIELDS.length; i++) {
                    FIELDS[i].el = document.getElementById(FIELDS[i].name);
                    if (FIELDS[i].el && FIELDS[i].mask) {
                        addBlur(FIELDS[i]);
                        FIELDS[i].maskEl = document.getElementById(FIELDS[i].name + '-mask');
                    }
                    if (FIELDS[i].el && FIELDS[i].required) {
                        FIELDS[i].requiredEl = document.getElementById(FIELDS[i].name + '-required');
                    }
                }
                handleForm(formEl, FIELDS);
                confirmEl = document.getElementById(CONFIRM);
                errorEl = document.getElementById(ERROR);
                btnEl = document.getElementById(BTN);
                document.getElementById(CLOSE).addEventListener('click', function() {
                    confirmEl.classList.remove('active');
                });
                document.getElementById(CLOSE_ERR).addEventListener('click', function () {
                    errorEl.classList.remove('active');
                });
            }
        });
    }
}