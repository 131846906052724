/*
    msbrowsers.js

    Handle the nonsense that is supplied to us from Microsoft's lovely IE11 and Edge.
*/

function fakeSlide(inner) {
    var int = window.setInterval(function() {
        var cld = inner.removeChild(inner.children[0]);
        inner.appendChild(cld);
    }, 5000);
}

function fixSS() {
    var slides = document.querySelectorAll('.inf-slide-container'), inner, i;
    if(slides && slides.length) {
        for(i = 0; i < slides.length; i++) {
            inner = slides[i].querySelector('.inf-slide-track');
            if(inner && inner.clientWidth > (slides[0].clientWidth * 2.2)) {
                fakeSlide(inner);
            }
        }
    }
}

module.exports = {


    init: function () {
        if(/Edge/.test(navigator.userAgent)) {
            //MS Edge
            fixSS();
        }
        if(/Trident/.test(navigator.userAgent)) {
            //IE11
        }
    }

}
